<template>
  <v-dialog persistent 
    v-model="activeModal"
    :max-width="variables.widthModal"
    
    class="modal-cadastro">
    <template v-slot:activator="{}">
        <v-row>
          <v-col cols="6">
            <v-select
              v-model="item.tipoBusca"
              :items="listaTipoBusca"
              menu-props="auto"
              hide-details
              label="Tipo busca"
              single-line
            ></v-select>
          </v-col>

          <v-col cols="6">
            <v-select
              v-model="item.tipoNotificacao"
              :items="listaTipoNotificacao"
              menu-props="auto"
              hide-details
              label="Meio de envio"
              single-line
            ></v-select>
          </v-col>
        </v-row>
    </template>

    <v-card>
      <v-toolbar flat height="40px" class="modal-cadastro-toolbar">
          <v-spacer></v-spacer>
          <v-icon @click="closeModal" class="modal-cadastro-close">close</v-icon>
      </v-toolbar>

      <v-form>
        <v-card-text class="modal-cadastro-form">
        </v-card-text>
        <v-card-text class="modal-cadastro-form">
         <v-textarea
          counter
          label="Mensagem"
          :error-messages="errorsMessage "
          v-model="item.message"
        ></v-textarea>
        </v-card-text>

        <v-card-actions class="modal-cadastro-footer">
          <v-btn :color="variables.colorPrimary" @click="closeModal" class="br-btn br-btn-cancelar">Cancelar</v-btn>
          <v-btn v-if="permiteAcao($route, 'add')" type="submit" :color="variables.colorPrimary" @click.prevent="submit" class="br-btn">Enviar</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import Events from '@/core/service/events'
import { required, } from 'vuelidate/lib/validators'
import variables from '@/assets/styles/helpers/_variables.scss'
// eslint-disable-next-line
import * as convertCvs from 'papaparse'
import { map, size } from 'lodash'
// eslint-disable-next-line
import { errorSnackbar, successSnackbar } from '@/core/service/utils'
export default {
  name: 'ModalSmsMensagem',
  data: () => ({
    activeModal: false,
    form: {},
    file: null,
    msgAcentuada: false,
    tipoBusca: 6,
    listaTipoBusca: [
      {text: 'ATS', value: 1 },
      {text: 'OSC ', value: 2 },
      {text: 'CREDENCIADOR ', value: 3 },
      {text: 'ESTABELECIMENTO ', value: 4 },
      {text: 'CAIXA ', value: 5 },
      {text: 'USUARIO WEB ', value: 6 },
      {text: 'INFLUENCIADOR', value: 7 }
    ]
  }),
  computed: {
    ...mapGetters('roles', ['permiteAcao']),
    ...mapGetters('notificacao', ['item']),
    ...mapState({ numeros: state => state.SendSms.numeros }),
    variables: () => variables,
    errorsMessage () {
      const errors = []
      if (!this.$v.item.message.$dirty) return errors
      !this.$v.item.message.required && errors.push('Digite uma mensagem')
      if (size(this.item.entityIds) === 0) errors.push('Selecione pelomenos um contato ou envie um arquivo')
      if (errors.length === 0) return ''
      return errors
    },
    listaTipoNotificacao () {
      return [
        {text: 'Plataforma Individual', value: 'PORTAL-IND' },
      ]
    }
  },
  mounted () {
    Events.$on('Dados::modal::sms', () => { this.activeModal = true })

    this.item.tipoNotificacao = 'PORTAL-IND'
  },
  watch: {
    tipoBusca (v) {
      this.addFiltroAtual(v).then(() => this.filtroEntities(v) )
    },
    file (val) {
      this.convertCvs(val)
    }
  },
  methods: {
    ...mapActions('notificacao', ['sendSms', 'addFiltroAtual', 'addDadosTable', 'cadastrarItem']),
    ...mapActions('notificacao', { getItensEntities: 'obterItens' }),
    convertCvs (val) {
      // var lines= val.split("\n");
      // eslint-disable-next-line
      const vm = this
      let arquivo = convertCvs.parse(val, {
        config: {
          header: true,
          encoding: "utf-8"
        },
        complete: (results) => {
          // eslint-disable-next-line
          vm.clear()
          setTimeout(() => {
            let dados = vm.formatCvsFile(results)
            vm.addDadosTable(dados)
          }, 500)
        }
      })
      console.log(arquivo)
    },
    openModal () { this.activeModal = true },

    filtroEntities (v = false) {
      const vm = this
      this.getItensEntities({
        pageSize: this.$store.getters.ItensPaginacao,
        _filter: { "entityTypeId_In":`${ !v ? this.tipoBusca : v}` }
      }).then(() => {
        vm.loadingTable = false
      }).catch(e => {
        errorSnackbar(e.message)
      })
    },
    formatCvsFile ({data}) {
      if (size(data) < 1) return false
      let array = []
      // chaves no header do arquivo
      let keys  = data[0]
      // eslint-disable-next-line
      let dados = map(data, (v, k) => {
        if (k > 0) { // pula a a posição 0 que é o as chaves do arquivo
          let obj = {}
          v.forEach((value, chave) =>{
            obj[keys[chave]] = value
          })
          array.push(obj)   
        }
      })
      return array
    },
    clear() {
      Events.$emit('sms:clearTable')
    },
    submit () {
      if (this.$v.item.message.$invalid || size(this.item.entityIds) === 0) {
        this.$v.item.$touch()
        return false
      }

      let entitiesIds = []

      this.item.entityIds.forEach(item => {
        entitiesIds.push(item.id)
      })

      let dados = {
        jsonData: this.item.message,
        channelId: this.item.tipoNotificacao,
        entityId: entitiesIds.length > 0 ? entitiesIds : undefined
      }
      this.cadastrarItem([dados]).then(() => {
        successSnackbar('Mensagem cadastrada com sucesso!')
        this.closeModal()
      }).catch(e => {
        errorSnackbar(e.message)
      })
    },
    formatDados () {
      let { form } = this
      let dados = this.numeros
      let text = String(form.message).split(' ')
      let regex = /[áàâãéèêíïóôõöúçñ/]/g
      let existeAcento = false
      
      text.forEach(nome => {
        let valido = nome.split(/ +/).every(parte => regex.test(parte))
        if (valido) { existeAcento = true }
      })

      if (size(dados)=== 0) return false
      // formata dados que vem da tabela ou por arquivo cvs, nunca dos dois
      dados = map(dados, v => ({
        number: 55 + String(v.numero || (`${v.ddd}${v.cellphone}`)).replace(/\s/g, ''),
        msg: form.message, code: existeAcento ? 8 : 0
      }))

      return dados
    },
    closeModal () {
      this.activeModal = false
    }
  },
  validations () {
    return {
      item: {
        message: {
          required
        }
      }
    }
  }
}
</script>

<style lang="scss">
@import '~@/assets/styles/components/modal-cadastro';
</style>
